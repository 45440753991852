import axios from "@/config/axios";

const initialState = () => {
  return {
    createFileStatus: false,
    createdFileData: null,
    createdFileError: null,
    allFiles: null,
    filePath: "",
    deleteStatus: false,
    deleteError: null,
    updateStatus: false,
    approveFilesStatus: false,
    rejectFilesStatus: false,
  };
};
const state = initialState();

const getters = {
  getCreateFileStatus: (state) => state.createFileStatus,
  getCreatedFileData: (state) => state.createdFileData,
  getCreatedFileError: (state) => state.createdFileError,
  getAllFiles: (state) => state.allFiles,
  getFilePath: (state) => state.filePath,
  getDeleteStatus: (state) => state.deleteStatus,
  getDeleteError: (state) => state.deleteError,
  getUpdateStatus: (state) => state.updateStatus,
  getApproveFilesStatus: (state) => state.approveFilesStatus,
  getRejectFilesStatus: (state) => state.rejectFilesStatus,
};
const mutations = {
  setCreateFileStatus: (state, data) => {
    state.createFileStatus = data;
  },
  setCreatedFileData: (state, data) => {
    state.createdFileData = data;
  },
  setCreatedFileError: (state, error) => {
    state.createdFileError = error;
  },
  setAllFiles: (state, data) => {
    state.allFiles = data;
  },
  setFilePath: (state, path) => {
    state.filePath = path;
  },
  setDeleteStatus: (state, status) => {
    state.deleteStatus = status;
  },
  setDeleteError: (state, error) => {
    state.deleteError = error;
  },
  setUpdateStatus: (state, status) => {
    state.updateStatus = status;
  },
  setApproveFilesStatus: (state, status) => {
    state.approveFilesStatus = status;
  },
  setRejectFilesStatus: (state, status) => {
    state.rejectFilesStatus = status;
  },
};
const actions = {
  createNewFile: async ({ commit }, params) => {
    try {
      commit("setCreateFileStatus", false);
      commit("setCreatedFileData", null);
      commit("setCreatedFileError", null);
      const response = await axios.post("fileManagement/create", params);
      commit("setCreateFileStatus", true);
      commit("setCreatedFileData", response.data.data);
    } catch (err) {
      console.log("err?.response?.data", err);
      if (err?.response?.data) {
        commit("setCreatedFileError", err.response.data);
      }
    }
  },
  getAllFiles: async ({ commit }, params) => {
    try {
      commit("setAllFiles", null);
      const response = await axios.get("fileManagement/all-files", { params });
      commit("setAllFiles", response.data);
    } catch (err) {
      console.log("error", err);
    }
  },
  fetchFilePath: async ({ commit }, id) => {
    try {
      commit("setFilePath", null);
      const response = await axios.get("fileManagement/file-path/" + id);
      commit("setFilePath", response.data.data);
    } catch (err) {
      console.log("error", err);
    }
  },
  deleteFile: async ({ commit }, id) => {
    try {
      commit("setDeleteStatus", false);
      commit("setDeleteError", null);
      await axios.delete("fileManagement/delete/" + id);
      commit("setDeleteStatus", true);
    } catch (err) {
      if (err?.response?.data?.message) {
        commit("setDeleteError", err.response.data.message);
      }
    }
  },
  updateFile: async ({ commit }, params) => {
    try {
      commit("setUpdateStatus", false);
      await axios.put("fileManagement/update/" + params.id, params);
      commit("setUpdateStatus", true);
    } catch (err) {
      console.log("error", err);
    }
  },
  approveFile: async ({ commit }, params) => {
    try {
      commit("setApproveFilesStatus", false);
      await axios.post("fileManagement/approve-files", params);
      commit("setApproveFilesStatus", true);
    } catch (err) {
      console.log("error", err);
    }
  },
  rejectFile: async ({ commit }, params) => {
    try {
      commit("setRejectFilesStatus", false);
      await axios.post("fileManagement/reject-files", params);
      commit("setRejectFilesStatus", true);
    } catch (err) {
      console.log("error", err);
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
