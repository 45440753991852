import { db } from "../utils/db";
import { postAPICall } from "../helpers/httpHelper";

export const setApplicationSetup = async () => {
  try {
    await clearDB(true);
    const [
      templates,
      entities,
      globalVariables,
      formbuilders,
      groups,
      entityViews,
      customDashboards,
      filters,
      entityCustomizations,
      icons,
    ] = await Promise.all([
      fetchApplicationCollections("/template/list", "TEMPLATES"),
      fetchApplicationCollections("/entities", "TEMPLATES"),
      fetchApplicationCollections("/global-variables", "GLOBAL_VARIABLES"),
      fetchApplicationCollections("/formBuilders", "FORM_BUILDERS"),
      fetchApplicationCollections("/entity-groups", "GROUPS"),
      fetchApplicationCollections("/entity-view-configuration", "ENTITY_VIEWS"),
      fetchApplicationCollections("/custom-dashboard", "CUSTOM_DASHBOARD"),
      fetchApplicationCollections(
        "/entity-filters/fetch-by-company",
        "FILTERS"
      ),
      fetchApplicationCollections(
        "/entity-customization/fetch-by-company",
        "ENTITY_CUSTOMIZATION"
      ),
      fetchApplicationCollections("/icons", "ICONS"),
    ]);
    await Promise.all([
      db.templates.bulkAdd(templates),
      db.entity.bulkAdd(entities),
      db.global_variables.bulkAdd(globalVariables),
      db.formbuilders_details.bulkAdd(formbuilders),
      db.entity_groups.bulkAdd(groups),
      db.entity_view_configuration.bulkAdd(entityViews),
      db.customDashboard.bulkAdd(customDashboards),
      db.filters.bulkAdd(filters),
      db.entity_customization.bulkAdd(entityCustomizations),
      db.icons.bulkAdd(icons),
    ]);
  } catch (e) {
    console.log("setApplicationSetup", e);
  }
};

export const clearDB = async (clearAllData = false) => {
  try {
    let allPromises = [
      db.templates.clear(),
      db.entity.clear(),
      db.global_variables.clear(),
      db.formbuilders_details.clear(),
      db.entity_groups.clear(),
      db.entity_view_configuration.clear(),
      db.customDashboard.clear(),
      db.filters.clear(),
      db.entity_customization.clear(),
    ];
    if (clearAllData) {
      allPromises.push(db.icons.clear());
    }
    await Promise.all(allPromises);
  } catch (e) {
    console.log("Error while clearing DB:", e);
  }
};

export const fetchApplicationCollections = async (url, type) => {
  try {
    let limit = ["GROUPS", "FILTERS", "GLOBAL_VARIABLES"].includes(
        type
      )
        ? 100
        : type == "TEMPLATES" ? 20 : 50,
      page = 1,
      data = [];
    const response = await postAPICall("GET", url, {
      ...(type == "TEMPLATES" && { include_standard: true }),
      limit: limit,
      page: page,
    });
    if (response?.data) {
      data = includeStandard(response, data, type);
      let totalPages = response.total_pages;
      let pageArray = [];
      for (let i = 2; i <= totalPages; i++) {
        pageArray.push(i);
      }
      await Promise.all(
        pageArray.map(async (p) => {
          const response = await postAPICall("GET", url, {
            ...(type == "TEMPLATES" && { include_standard: true }),
            limit: limit,
            page: p,
          });
          if (response?.data) {
            data = includeStandard(response, data, type);
          }
        })
      );

      return data.map((d) => {
        d.createdAt = new Date().toISOString();
        return d;
      });
    }
  } catch (e) {
    console.log("getAllCompanyTemplates", e);
    return [];
  }
};

const includeStandard = (response, data, type) => {
  if (response?.data) {
    if (!["TEMPLATES", "ENTITY"].includes(type)) {
      return [...data, ...response.data];
    }
    if (
      response.data.find((e) => e.type === "STANDARD") &&
      !data.find((e) => e.type === "STANDARD")
    ) {
      data = [
        ...data,
        ...[response.data.find((e) => e.type === "STANDARD")].concat(
          response.data.filter((e) => e.type !== "STANDARD")
        ),
      ];
    } else {
      data = [...data, ...response.data.filter((e) => e.type !== "STANDARD")];
    }
  }
  return data;
};

export const fetchAllIcons = async () => {
  const icons = await fetchApplicationCollections(
    "/icons/getAllIcons",
    "ICONS"
  );
  await db.icons.bulkAdd(icons);
};
