<template>
  <div id="app" class="w-screen h-screen">
    <component :is="layout" v-if="!showErrorMessage">
      <router-view :layout.sync="layout" />
    </component>
    <internet-error v-else />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import InternetError from "./components/application/internetError.vue";
import AppConfiguration from "./config/app";
export default {
  name: "App",
  components: {
    InternetError,
  },
  computed: {
    ...mapGetters("auth", ["getActiveWorkspace"]),
    ...mapGetters("company", ["getCompanyDetails"]),
  },
  data() {
    return {
      showErrorMessage: !navigator.onLine,
      layout: "div",
      online: true,
    };
  },
  metaInfo: {
    title: "eSigns: Electronic Signature Software on a free trial",
    titleTemplate: "%s | eSigns.io",
    htmlAttrs: {
      lang: "en-US",
    },
    link: [
      // { rel: 'stylesheet', href: '/css/index.css' },
      { rel: "favicon", href: "eSignFavicon.png" },
    ],
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Start exploring the joy of working in a paperless work environment with eSigns the next-generation software that offers free electronic signature for a trial period.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        property: "og:title",
        content: "eSigns: Electronic Signature Software on a free trial",
      },
      { property: "og:site_name", content: "eSigns" },
      { property: "og:type", content: "website" },
      { name: "robots", content: "index,follow" },
    ],
  },
  beforeMount() {},
  mounted() {
    this.appSettings = AppConfiguration;
    window.addEventListener("online", this.handleOnline);
    window.addEventListener("offline", this.handleOffline);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.handleOnline);
    window.removeEventListener("offline", this.handleOffline);
  },
  methods: {
    amIOnline(e) {
      this.online = e;
    },
    backToHome() {
      this.$router.go();
    },
    handleOnline() {
      this.showErrorMessage = false;
    },
    handleOffline() {
      this.showErrorMessage = true;
    },
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
}
.offline {
  .division {
    text-align: center;
  }
  .para {
    font-size: 19px;
    font-weight: 300;
    color: rgb(0, 0, 0);
  }
  .heading {
    font-size: 33px;
    font-weight: 500;
    color: black;
  }
}
</style>
