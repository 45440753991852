const initialState = () => {
  return {
    urlsSavingData: {
      selectedAttachmentFile: null,
      routeUrl: null,
      aiData: null,
    },
  };
};

const state = initialState();

const getters = {
  getUrlsSavingData: (state) => state.urlsSavingData,
};

const mutations = {
  setUrlsSavingData: (state, data) => {
    state.urlsSavingData = data;
  },
};

const actions = {
  updateUrlsSavingData(context, newData) {
    context.commit("setUrlsSavingData", newData);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
